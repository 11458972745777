import React from "react";
import MainNavBar from "./MainNavBar";
import { graphql, StaticQuery } from "gatsby";
import styles from "../styles/services.css";
import Footer from "./Footer";
import { GatsbyImage, getImage, StaticImage } from "gatsby-plugin-image";
import jsonData from "./services.json"

export const query = graphql`
  query service {
    ourServicesGatsby: file(relativePath: { eq: "servicePage.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH)
      }
    }

    dataJson {
      our_services_title_deluxe
      our_services_left_title
    }
  }
`;

function Services({ data }) {
  const ourServicesGatsby = getImage(data.ourServicesGatsby);
console.log(jsonData)
  return (
    <div>
      <div>
        <MainNavBar />
      </div>
      <div className="about-us-cnt">
        <h1 className="about-us-title">
          {jsonData.our_services_title_deluxe}
        </h1>
      </div>

      <section className="main-box service-box">
        <div className="main-about-us-cnt services-smaller-width">
          <div className="text-card service-card">
            <h3 className="who-are-we service-title">
              {jsonData.our_services_left_title}
            </h3>
            <ul className="guidline-ctn">
              <li>Long Term Care</li>
              <li>Short Term Post-Acute Rehabilitation</li>
              <li>Medical Services</li>
              <li>Dementia and Alzheimer’s Care</li>
              <li>Resident Personal Care & Amenities</li>
              <li>Sub-Acute Rehabilitation</li>
              <li>Pain Management</li>
              <li>Respiratory Care</li>
              <li>Nursing Rehabilitation</li>
              <li>Wound Care</li>
              <li>Parenteral Care (IV, central lines, PICC)</li>
              <li>Dementia Focused Care</li>
              <li>Palliative Care</li>
              <li>Comfort Care</li>
            </ul>
          </div>
        </div>
      </section>

      <section className="image-about-us-box">
        <div className="image-about-us-cnt services-smaller-width">
          <div className="image-card">
            <GatsbyImage
              className="image-about-us-newPage"
              placeholder="blurred"
              image={ourServicesGatsby}
            />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default Services;
